import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import img1 from '../../../../src/assets/Foto/recurs02.jpg';
import img2 from '../../../../src/assets/Foto/recurs03.jpg';
  
function Biblioteca() {
    return (
        <>
        <header id='header'>
        <AppHeader />
        </header>
        <section>
        <div id='backImageSubPage' className="banner position-relative">
        <div className='title-banner' id='title-unidade' >
        <h1>Laboratório</h1>
        </div>
        </div>
        </section>
            <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
                <br />
                <div className="details-magazine" id='detalhes'>
                    <p className="f-reg">
                    <b className="title-apresentation display-6 mb-3"style={{ color:'#97be4a'}}>
                    Laboratório de Pesquisa e Inovação</b>
                    <br />
                    <br />
                    No Laboratório de Pesquisa e Inovação do Instituto Superior, alunos, pesquisadores e interessados terão acesso a um ambiente de aprendizado e desenvolvimento de ponta, onde a ciência, a tecnologia e a inovação se encontramados. Nós entendemos que os laboratórios são componentes essenciais em instituições de ensino e pesquisa, oferecendo uma série de benefícios tanto para os alunos quanto para a comunidade acadêmica e até para o mercado. Ter laboratórios bem estruturados traz vantagens significativas
                    <br />
                    <br />
                    Este ambiente é o ponto de partida para aqueles que buscam transformar suas ideias em inovações reais, com impacto no mercado e na comunidade científica.
                    <br /><br />
                    <br /><br />
                    <b className="title-apresentation display-6 mb-3"style={{ color:'#97be4a'}}>Nosso laboratório oferece</b>
                    <br /><br />
                    <ul>
                        <li>
                        <strong>1- Infraestrutura de Última Geração</strong>: Equipamentos avançados e instalações modernas, projetados para apoiar projetos acadêmicos, pesquisas científicas e práticas laboratoriais
                        </li>
                        <li>
                        <strong>2- Oportunidades de Pesquisa</strong>: Espaço para alunos e professores desenvolverem projetos de pesquisa aplicada em diversas áreas do conhecimento, com foco em resultados inovadores e soluções para desafios contemporâneos.</li>
                        <li>
                        <strong>3- Colaboração Interdisciplinar</strong>: Projetos colaborativos entre diferentes áreas de estudo, fomentando a troca de ideias e o desenvolvimento de soluções criativas e inovadoras.
                        </li>
                        <li>
                        <strong>4- Programas de Estágio e Iniciação Científica</strong>: Oportunidades para alunos se envolverem em pesquisas de ponta, contribuindo com novos conhecimentos e aprimorando suas habilidades práticas e acadêmicas.
                        </li>
                        <li>
                        <strong>5- Parcerias com a Indústria e Instituições de Pesquisa</strong>: O laboratório mantém parcerias com empresas e outras instituições acadêmicas, criando uma rede de colaboração que amplia as possibilidades de inovação.
                        </li>
                        <li>
                        <strong>6- Desenvolvimento de Novos Produtos e Soluções</strong>: O espaço promove o desenvolvimento de protótipos e novas tecnologias, incentivando o espírito empreendedor e o impacto positivo na sociedade.
                        </li>
                    </ul>
                    <section id="about" className="block about-block">
                    <Container fluid>
                        <div className="title-holder">
                        </div>
                        <Row>
                        <Col sm={6}>
                            <Image className='image_Quem' src={img1} />
                           
                        </Col>
                        <Col sm={6}>
                            <Image className='image_Quem' src={img2} />
                        </Col>
                        </Row>
                    </Container>
                    </section>
                    </p>
                </div>
                
                <br />
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 menu-color">
                <a href="/Laboratorio" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-success mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg " style={{ color:'#97be4a'}}>
                    Laboratório
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/Biblioteca" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                   Biblioteca
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>            
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </main>
        <AppLayout />
        </>
       
    );
}

export default Biblioteca;