import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import img1 from '../../../../src/assets/Foto/recurs01.jpg';

  
function Biblioteca() {
    return (
        <>
        <header id='header'>
        <AppHeader />
        </header>
        <section>
        <div id='backImageSubPage' className="banner position-relative">
        <div className='title-banner' id='title-unidade' >
        <h1>Biblioteca</h1>
        </div>
        </div>
        </section>
            <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
                <br />
                <div className="details-magazine" id='detalhes'>
                    <p className="f-reg">
                    <b className="title-apresentation display-6 mb-3"style={{ color:'#97be4a'}}>Seu Espaço de Conhecimento, Pesquisa e Crescimento Acadêmicoa</b>
                    <br />
                    <br />
                    Descubra um ambiente dedicado ao seu sucesso acadêmico, onde recursos de ponta e apoio especializado estão ao seu alcance. A nossa oferece um espaço dinâmico e inovador, ideal para o desenvolvimento do seu conhecimento, aprimoramento da pesquisa e impulsionamento da sua trajetória acadêmica. Explore nossos materiais, tecnologias e serviços personalizados para maximizar seu aprendizado e alcançar os melhores resultados.
                    <br />
                    <br />
                    Seja para aprofundar seus conhecimentos, elaborar trabalhos de qualidade ou explorar novas áreas do saber, a nossa biblioteca é o espaço ideal para fomentar seu crescimento acadêmico e profissional. Aproveite as oportunidades de acesso a materiais exclusivos, serviços de consulta, workshops e apoio contínuo, e dê o próximo passo em direção ao seu futuro de sucesso.
                    <br /><br />
                    <br /><br />
                    <b className="title-apresentation display-6 mb-3"style={{ color:'#97be4a'}}>Suporte ao Sucesso Acadêmico</b>
                    <section id="about" className="block about-block">
                    <Container fluid>
                        <div className="title-holder">
                        </div>
                        <Row>
                        <Col sm={6}>
                            <Image className='image_Quem' style={{height:'420px'}} src={img1} />
                        </Col>
                        <Col sm={6}>
                            <p>
                            Instituto Superior Politécnico Privado Do Luena possui um conjunto de bibliotecas modernas e bem estruturadas, projetadas para atender às necessidades de seus alunos, docentes e pesquisadores. Cada biblioteca do instituto oferece um ambiente único, mas todas compartilham o compromisso de fornecer recursos de alta qualidade, espaços de estudo confortáveis e apoio contínuo ao aprendizado e à pesquisa acadêmica. 
                            </p>
                            <p>
                                Cada biblioteca do Instituto Superior Politécnico Privado Do Luena foi cuidadosamente projetada para oferecer recursos e serviços especializados que atendem às necessidades dos alunos de diferentes áreas de estudo. Com uma infraestrutura moderna e acolhedora, elas se destacam pela variedade de recursos físicos e digitais, espaços de estudo adequados e apoio especializado, proporcionando um ambiente ideal para o desenvolvimento acadêmico e a realização de projetos de pesquisa de alta qualidade.
                            </p>
                        </Col>
                        </Row>
                    </Container>
                    </section>
                    </p>
                </div>
                
                <br />
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 menu-color">
                <a href="/Laboratorio" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-success mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg " style={{ color:'#97be4a'}}>
                    Laboratório
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/Biblioteca" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                   Biblioteca
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>            
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </main>
        <AppLayout />
        </>
       
    );
}

export default Biblioteca;