import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
    {
      id: 2,
      icon: 'fas fa-snowflake',
      title: 'Gestão de Empresas',
      description: 'Este curso é ideal para quem deseja adquirir habilidades essenciais para administrar e liderar empresas de forma eficaz.'
    },
    {
      id: 3,
      icon: 'fas fa-plug',
      title: 'Pedagogia',
      description: 'Desenvolva habilidades essenciais para transformar a educação com o nosso curso de Pedagogia. Abordamos metodologias inovadoras, práticas pedagógicas e o papel do educador no desenvolvimento integral dos alunos.'
    },
    {
      id: 5,
      icon: 'fas fa-trophy',
      title: 'Sociologia',
      description: 'Desenvolva uma compreensão crítica das estruturas sociais e das relações humanas, e prepare-se para analisar e enfrentar desafios sociais contemporâneos. '
    },
    {
      id: 6,
      icon: 'fas fa-life-ring',
      title: 'Direito',
      description: 'Oferecemos uma formação completa nas áreas de legislação, jurisprudência e prática jurídica, capacitando você a atuar com ética e excelência.'
    },
    {
      id: 7,
      icon: 'fas fa-life-ring',
      title: 'Arquitetura',
      description: 'Transforme suas ideias em realidade com o nosso curso de Arquitetura. Explore o design inovador, a funcionalidade e a sustentabilidade em projetos arquitetônicos.'
    },
    {
      id: 8,
      icon: 'fas fa-life-ring',
      title: 'Gestão Recursos Humanos',
      description: 'Explore técnicas de recrutamento, desenvolvimento de talentos, administração de benefícios e criação de uma cultura organizacional positiva.'
    },
    {
      id: 9,
      icon: 'fas fa-life-ring',
      title: 'Economia',
      description: 'Mergulhe no universo das finanças e políticas econômicas com o nosso curso de Economia, prepare-se para entender e influenciar a tomada de decisões em diversos setores, desde negócios até o governo.'
    },
    {
      id: 10,
      icon: 'fas fa-desktop',
      title: 'Enfermagem',
      description: 'Aprofunde-se em áreas como cuidados críticos, saúde comunitária e administração de medicamentos, e aprenda a atuar com competência e empatia em diversos ambientes de saúde.'
    },
  ]
  
function Licenciatura() {
    return (
        <>
        <header id='header'>
        <AppHeader />
        </header>
        <section>
        <div id='backImageSubPage' className="banner position-relative">
        <div className='title-banner' id='title-unidade' >
        <h1>Licenciatura</h1>
        </div>
        </div>
        </section>
            <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
                <br />
                <div className="details-magazine" id='detalhes'>
                    <p className="f-reg">
                    <b className="title-apresentation display-6 mb-3"style={{ color:'#97be4a'}}>Descubra a Licenciatura que Pode Mudar sua Vida</b>
                    <br />
                    <br />
                    Na busca por um caminho profissional gratificante e transformador, a escolha da licenciatura certa é fundamental. No nosso Instituto Superior, oferecemos uma variedade de cursos que não apenas preparam você para o mercado de trabalho, mas também despertam sua paixão e potencial.
                    <br />
                    <br />
                    <h2 className='title-apresentation' style={{ color:'#97be4a'}}>Por que escolher nossa licenciatura?</h2>
                    <ul>
                        <li><strong>1. Currículo Inovador:</strong> Nossos programas são projetados para atender às demandas do mercado, com foco nas habilidades e conhecimentos que realmente fazem a diferença.</li>
                        <li><strong>2. Corpo Docente Qualificado: </strong>Aprenda com professores experientes e dedicados, que são especialistas em suas áreas e comprometidos com o seu sucesso.</li>
                        <li><strong>3. Experiência Prática:</strong> Oferecemos estágios e projetos práticos que permitem aplicar o que você aprende em sala de aula, preparando-o para os desafios do mundo real..</li>
                        <li><strong>4. Suporte ao Aluno:</strong> Desde o primeiro dia, nossa equipe está aqui para apoiar você em sua jornada acadêmica e profissional, oferecendo orientação e recursos.</li>
                    </ul>
                    <br />
                    <p>Venha descobrir como uma licenciatura pode ser o primeiro passo para uma carreira de sucesso e uma vida plena. Inscreva-se já e comece a trilhar o caminho para o seu futuro.</p>
                    </p>
                </div>
                <section id="services" className="block services-block">
                <Container fluid>
                    <div className="title-holder">
                    <h2>Cursos de Licenciatura</h2>
                    <div className="subtitle">Transforme sua carreira e realize seus sonhos com uma educação que faz a diferença!</div>
                    </div>
                    <Row >
                    {
                        servicesData.map(services => {
                        return (
                            <Col sm={4} className='holder'style={{border:'1px solid #97BE49', borderRadius:'12px'}} key={services.id}>
                            <div className="icon">
                                <i className={services.icon}></i>
                            </div>
                            <h3 style={{color:'#00761F'}}>{services.title}</h3>
                            <p>{services.description}</p>
                            </Col>
                        );
                        })
                    }
                    </Row>
                </Container>
                </section>
                <br />
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 menu-color">
                <a href="/Licenciatura" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-success mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg " style={{ color:'#97be4a'}}>
                    Licenciatura
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/Unidade_organica" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Unidade Orgânica
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>            
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </main>
        <AppLayout />
        </>
       
    );
}

export default Licenciatura;