import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './App.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


// página home
import Home from './pages/home/home';

// páginas da seçção Instituição
import Instituicao from './pages/instituicao/index.js';
import QualidadeInstituicao from './pages/instituicao/Qualidade/index.js';
import MissaInstituicaoInstituicao from './pages/instituicao/missao_visao_valores/index.js';

// páginas da seçção Programas

import UnidadeOrganica from './pages/programas/unidade_organica/index.js';
import Licenciatura from './pages/programas/licenciatura/index.js';

// páginas da seçção Recursos

import Biblioteca from './pages/recursos/Biblioteca/index.js'
import Laboratorio from './pages/recursos/laboratorio/index.js'

const router = createBrowserRouter([

  // páginas da página Home
  {
    path:"/",
    element:<Home />,
  },
  // páginas da seçção Instituição
  {
    path:"instituicao",
    element:<Instituicao />,
  },
  // páginas da seçção Programas
  {
    path:"Unidade_organica",
    element:<UnidadeOrganica />,
  },
  // páginas da seçção Instituição
  {
    path:"Qualidade",
    element:<QualidadeInstituicao />,
  },
  {
    path:"missao_visao_valores",
    element:<MissaInstituicaoInstituicao />,
  },
  {
    path:"Licenciatura",
    element:<Licenciatura />,
  },
  {
    path:"Biblioteca",
    element:<Biblioteca />,
  },
  {
    path:"Laboratorio",
    element:<Laboratorio />,
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
